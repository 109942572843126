#Causes {
    background-color: #003B71;
    max-width: 1440px;
    margin: 0 auto;
    padding: 4rem 0;
}

#Causes h1 {
    color: white;
    max-width: 95%;
    margin: 0 auto;
    margin-bottom: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-size: 1.2rem;
    text-align: center;
}

#Causes-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.Causes-cause-item {
    background: white;
    max-width: 100%;
    width: 24rem;
    min-height: 28rem;
    height: auto;
    padding-bottom: 3rem;
    margin-right: 1rem;
    margin-left: 1rem;
    border-top: 0.4rem solid #922C48;
    position: relative;
    margin-bottom: 2rem;
}

.Causes-cause-item figure {
    width: 100%;
    height: 14rem;
    background-size: cover;
}

.Causes-cause-item div {
    padding: 1rem;
}

.Causes-cause-item div h3 {
    font-family: serif;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    color: #922C48;
}

.Causes-cause-item div p {
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.7);
    line-height: 1.15rem;
    padding-right: 1rem;
}

.Causes-cause-item button {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0.5rem;
    border: none;
    background-color: #922C48;
    color: white;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    cursor: pointer;
    transition: all 0.25s;
}

.Causes-cause-item button:hover {
    opacity: 0.85;
}

#Causes .container-fluid {
    padding: 0 8rem;
}

@media only screen and (max-width: 1300px) {
    #Causes .container-fluid {
        padding: 0 6rem;
    }
}

@media only screen and (max-width: 1100px) {
    #Causes .container-fluid {
        padding: 0 4rem;
    }
}

@media only screen and (max-width: 1000px) {
    #Causes .container-fluid {
        padding: 0 2rem;
    }
}

@media only screen and (max-width: 900px) {
    .Causes-cause-item {
        min-height: 30rem;
    }
}

@media only screen and (max-width: 800px) {
    #Causes .container-fluid {
        padding: 0 1rem;
    }
}