header {
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    height: 4.5rem;
    z-index: 100;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

#Header-logo-section {
    display: flex;
    align-items: center;
}

#Header-logo-section h1 {
    padding-left: 1.75rem;
    text-transform: uppercase;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
    padding-right: 1rem;
    width: 16rem;
}

#Header-logo-section h2 {
    padding-left: 2rem;
    color: #003B71;
    position: relative;
    top: 0.25rem;
    font-size: 1.55rem;
    letter-spacing: 0.01rem;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
}
#Header-logo-section h1 span {
    margin-right: 0.25rem;
    font-weight: bold;
}

#Header-logo-section h1 span:nth-child(1) {
    color: #003B71;
    font-size: 1.5rem;
}

#Header-logo-section h1 span:nth-child(2) {
    color: #922C48;
    font-size: 1.85rem;
}

#Header-logo-section h1 span:nth-child(3) {
    color: #225D38;
    font-size: 2.3rem;
}

#Header-options-social-section {
    height: 100%;
    display: flex;
    align-items: center;
}

#Header-options-social-section article {
    margin-right: 1rem;
    color: #922C48;
    font-weight: bold;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    padding: 0 1rem;
    transition: all 0.25s;
}

#Header-options-social-section article:hover {
    background: #fafafa;
}

#Header-options-social-section article:active {
    background: #f1f1f1;
}


#Header-options-social-section article span {
    cursor: pointer;
}

#Header-options-social-section article img {
    margin-left: 0.75rem;
    cursor: pointer;
}

#Header-options-social-section section {
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    padding: 0 1.75rem;
    height: 100%;
    display: flex;
    align-items: center;
}

#Header-options-social-section section a {
    display: flex;
    align-items: center;
}

.HeaderBar-social-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
}

.HeaderBar-mobile-logo {
    display: none;
}

#Header-cause-name-container {
    display: none;
    position: absolute;
    top: 4.5rem;
    left: 0;
    right: 0;
    background: white;
    height: 3rem;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin: 0 !important;
    align-items: center;
    justify-content: center;
}


#Header-cause-name-container span {
    color: #003B71;
    font-weight: bold;
    font-size: 1.25rem;
}

@media only screen and (max-width: 1440px) {
    header {
        left: 0;
        right: 0;
    }
}

@media only screen and (max-width: 1000px) {
    #Header-logo-section h2 {
        display: none !important;
    }
}

@media only screen and (max-width: 700px) {
    #Header-options-social-section article {
        position: absolute;
        top: 7.5rem;
        left: 0;
        right: 0;
        background: white;
        height: 3rem;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        margin: 0 !important;
        justify-content: center;
    }
    #Header-cause-name-container {
        display: flex;
    }
}

@media only screen and (max-width: 600px) {
    .HeaderBar-mobile-logo {
        display: block;
    }
    .HeaderBar-normal-logo {
        display: none;
    }
}

@media only screen and (max-width: 500px) {
    #Header-logo-section h1 {
        width: auto;
    }
}