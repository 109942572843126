#FilterMenu {
    background: white;
    padding: 1.75rem 0;
    display: flex;
    align-items: center;
    top: 4.5rem;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    position: absolute;
    width: 100%;
    z-index: 100;
}

#FilterMenu article {
    margin-left: 2rem;
    display: flex;
}

#FilterMenu article figure {
    margin-right: 3rem;
    display: flex;
    align-items: center;
}

#FilterMenu article figure input {
    margin-right: 0.75rem;
    height: 1.2rem;
    width: 1.2rem;
    cursor: pointer;
}

#FilterMenu article figure label {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.2rem;
    font-size: 1.3rem;
}

#FilterMenu img {
    margin-right: 2rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    transition: all 0.25s;
}

#FilterMenu img:hover {
    opacity: 0.7;
}

.FilterMenu-label-choice {
    color: #003B71;
}

.FilterMenu-label-voice {
    color: #922C48;
}

.FilterMenu-label-vote {
    color: #225D38;
}

#FilterMenu-all-hr {
    margin-bottom: 1rem;
    max-width: 100%;
    width: 15rem;
    display: none;
}

@media only screen and (max-width: 1440px) {
    #FilterMenu {
        left: 0 !important;
        right: 0 !important;
    }
}

@media only screen and (max-width: 700px) {
    #FilterMenu {
        top: 10.5rem !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 0 !important;
    }
    #FilterMenu article {
        display: flex;
        flex-direction: column;
    }
    #FilterMenu article figure {
        margin-bottom: 2rem;
    }
    #FilterMenu img {
        position: absolute;
        top: 2rem; 
        right: 0;
    }
    #FilterMenu-all-hr {
        display: block;
    }
}