#Hero {
    height: 55vh;
    background-size: cover;
    background-position: center 23%;
    max-width: 1440px;
    margin: 0 auto;
    background-color: #225D38;
}

#Hero article {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    max-width: 50%;
    margin: 0 auto;
    padding-top: 7rem;
}

#Hero article h3 {
    color: #003B71;
    font-size: 2.5rem;
    text-transform: uppercase;
    line-height: 2rem;
}

#Hero article h2 {
    color: #922C48;
    font-size: 3.45rem;
    text-transform: uppercase;
    line-height: 3.5rem;
}

#Hero article h1 {
    color: #225D38;
    font-size: 4.35rem;
    text-transform: uppercase;
    line-height: 3.5rem;
}

#Hero article h5 {
    font-family: serif;
    color: rgba(0, 0, 0, 0.7);
    font-size: 2rem;
    font-weight: normal;
    padding: 1rem 4rem;
    line-height: 2rem;
}