#AboutPanel {
    width: 18.75rem; 
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: 2rem;
}

#AboutPanel div {
    margin: 0 2rem;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    padding: 0.75rem 0 2rem 0;
    margin-bottom: 4rem;
}

#AboutPanel div img {
    width: 100%;
    margin-top: 1rem;
}

#AboutPanel-main-image {
    border-top: 0.4rem solid #922C48 !important;
}

#AboutPanel div h1 {
    font-family: serif;
    font-size: 1.5rem;
    margin-top: 1.5rem;
}

#AboutPanel div p {
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 0.8rem;
    font-weight: normal;
    line-height: 1.1rem;
    color: rgba(0, 0, 0, 0.6);
}

#AboutPanel div h5 {
    font-size: 0.8rem;
    text-align: center;
    letter-spacing: 0.05rem;
    margin-top: 2rem;
    opacity: 0.8;
}

#AboutPanel div section {
    display: flex;
    max-width: 50%;
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 0;
}

#AboutPanel div a {
    /* height: 1.25rem; */
    /* width: 1.25rem; */
}

#AboutPanel div a img {
    height: 1.25rem;
    width: 1.25rem;
}

#AboutPanel-logo-container {
    display: flex;
    max-width: 80%;
    width: 80%;
    margin: 1rem auto 0.5rem auto !important;
}

#AboutPanel h4 {
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 0.65rem;
}

#AboutPanel-share-section {
    padding: 0.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin: 2rem 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    transition: all 0.25s;
}

#AboutPanel-share-section:hover {
    background: rgb(217, 222, 228);
}

#AboutPanel-share-section:active {
    background: rgb(206, 211, 216);
}

#AboutPanel-share-section span {
    font-size: 0.8rem;
    font-weight: bold;
    color: #922C48;
}

#AboutPanel-share-section img {
    height: 1.2rem;
    width: auto !important;
    margin: 0 !important;
    margin-left: 0.5rem !important;
}

#AboutPanel-share-section-visible {
    padding: 0.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#AboutPanel-share-section-visible span {
    font-size: 0.8rem;
    font-weight: bold;
    color: #225D38;
}

#AboutPanel-share-section-visible figure {
    display: flex;
    justify-content: space-between;
    width: 55%;
}

#AboutPanel-share-section-visible figure a {
    height: 1.25rem;
    width: 1.25rem;
}

#AboutPanel-share-section-visible figure img {
    height: 1.25rem;
    width: 1.25rem;
    margin: 0 !important;
    max-width: 100% !important;
}

.AboutPanel-share-image {
    cursor: pointer;
    position: relative;
}

#AboutPanel-copy-popup {
    position: absolute; 
    background: white;
    padding: 0.5rem;
    z-index: 1000000; 
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2); 
    max-width: 100%;
    width: auto !important;
    display: flex;
    justify-content: center !important;
}

#AboutPanel-cause-sponsor p {
    text-align: center;
    opacity: 1 !important;
    color: black !important;
}

#AboutPanel-cause-sponsor h1 {
    text-align: center;
    font-family: sans-serif !important;
    margin-top: 0 !important;
}

#AboutPanel-cause-sponsor a {
    /* display: flex;
    justify-content: center; */
    text-decoration: none;
    color: #922C48;
    font-weight: bold;
    /* margin: 0 auto; */
    font-size: 0.8rem;
    overflow-wrap: anywhere;
    overflow: hidden;
    width: 100%;
    text-align: center;
}

#AboutPanel-cause-sponsor aside {
    display: flex;
    margin-top: 0.5rem;
    width: 100%;
}

@media only screen and (max-width: 700px) {
    #AboutPanel {
        display: none;
    }
}