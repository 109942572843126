body {
  margin: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem !important;
  background-color: #E5EBF1 !important;
  color: black;
  height: 100%;
}

* {
  margin: 0;
  /* box-sizing: border-box; */
}

.choice-color-svg {
  fill: #003B71;
}

.voice-color-svg {
  fill: #922C48;
}

.vote-color-svg {
  fill: #225D38;
}

#MobileFooter {
  background: none;
  display: none;
}

#MobileFooter figure {
  justify-content: center;
}

#MobileFooter section {
  width: 8rem !important;
  margin-top: 1rem;
}

#MobileFooter section a,
#MobileFooter section a img {
  width: 1.25rem;
  height: 1.25rem;
}

.full-width {
  max-width: 100%;
  width: 100%;
}

.grid-sizer,
.grid-item {
  width: 22rem;
}

.grid-item {
  /* float: left; */
  /* height: 30rem; */
}

.grid-item--width2 { width: 22rem; }
/* .grid-item--height2 { height: 14.25rem; } */

@media only screen and (max-width: 1110px) {
  #MobileFooter {
      display: block;
  }
}

@media only screen and (max-width: 1451px) {
  .grid-sizer,
  .grid-item {
    width: 21rem
  }
  .grid-item--width2 { width: 21rem; }
}

@media only screen and (max-width: 1390px) {
  .grid-sizer,
  .grid-item {
    width: 20rem
  }
  .grid-item--width2 { width: 20rem; }
}

@media only screen and (max-width: 1329px) {
  .grid-sizer,
  .grid-item {
    width: 19rem
  }
  .grid-item--width2 { width: 19rem; }
}

@media only screen and (max-width: 1268px) {
  .grid-sizer,
  .grid-item {
    width: 18rem
  }
  .grid-item--width2 { width: 18rem; }
}

@media only screen and (max-width: 1207px) {
  .grid-sizer,
  .grid-item {
    width: 17rem
  }
  .grid-item--width2 { width: 17rem; }
}

@media only screen and (max-width: 1146px) {
  .grid-sizer,
  .grid-item {
    width: 16rem
  }
  .grid-item--width2 { width: 16rem; }
}

@media only screen and (max-width: 1095px) {
  .grid-sizer,
  .grid-item {
    width: 15rem
  }
  .grid-item--width2 { width: 15rem; }
}

@media only screen and (max-width: 1047px) {
  .grid-sizer,
  .grid-item {
    width: 22rem
  }
  .grid-item--width2 { width: 22rem; }
}

@media only screen and (max-width: 1015px) {
  .grid-sizer,
  .grid-item {
    width: 21rem
  }
  .grid-item--width2 { width: 21rem; }
}

@media only screen and (max-width: 983px) {
  .grid-sizer,
  .grid-item {
    width: 20rem
  }
  .grid-item--width2 { width: 20rem; }
}

@media only screen and (max-width: 951px) {
  .grid-sizer,
  .grid-item {
    width: 19rem
  }
  .grid-item--width2 { width: 19rem; }
}

@media only screen and (max-width: 919px) {
  .grid-sizer,
  .grid-item {
    width: 18rem
  }
  .grid-item--width2 { width: 18rem; }
}

@media only screen and (max-width: 887px) {
  .grid-sizer,
  .grid-item {
    width: 17rem
  }
  .grid-item--width2 { width: 17rem; }
}

@media only screen and (max-width: 855px) {
  .grid-sizer,
  .grid-item {
    width: 16rem
  }
  .grid-item--width2 { width: 16rem; }
}

@media only screen and (max-width: 823px) {
  .grid-sizer,
  .grid-item {
    width: 15rem
  }
  .grid-item--width2 { width: 15rem; }
}

@media only screen and (max-width: 791px) {
  .grid-sizer,
  .grid-item {
    width: 95%;
  }
  .grid-item--width2 { width: 95%; }
}

@media only screen and (max-width: 700px) {
  .grid-sizer,
  .grid-item {
    width: 100%;
  }
  .grid-item--width2 { width: 100%; }
  .dont-display {
    display: none !important;
  }
  
  .display {
    display: block !important;
  }
}
