footer {
    max-width: 1440px;
    margin: 0 auto;
    padding: 4rem 0;
    text-align: center;
    background: #205934;
}

footer section {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 1rem;
}

footer p {
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 1rem;
    font-size: 0.85rem;
    letter-spacing: 0.05rem;
}

#Footer-logo {
    width: 8rem;
    height: auto;
}

.Footer-social-icon {
    width: 2rem;
    height: 2rem;
}
