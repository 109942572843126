#SubCauses {
    position: relative;
    /* width: calc(1440px - 20.75rem); */
    margin-top: 2rem;
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
}

.SubCauses-item {
    margin-right: 1rem;
    margin-bottom: 1rem;
    background: white;
}

.SubCauses-item section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    height: 1rem;
}

.SubCauses-item section img {
    height: 1rem;
    width: 1rem;
}

.SubCauses-item section h3 {
    color: white;
    font-size: 0.8rem;
    letter-spacing: 0.015rem;
}

.SubCauses-item figure {
    width: 100%;
    height: 12.25rem;
    background-size: cover;
    background-position: center;
}

.SubCauses-item div {
    padding: 1rem;
    background: white;
}

.SubCauses-item article {
    display: flex;
    align-items: center;
    padding: 0 1rem 1.5rem 1rem;
    background: white;
}

.SubCauses-item div h3 {
    font-family: serif;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    color: #922C48;
}

.SubCauses-item div p {
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.7);
    line-height: 1.15rem;
    padding-right: 1rem;
}

.SubCauses-sponsor {
    border-bottom: 0.5rem solid black;
}

.SubCauses-sponsor section {
    background: black;
}

.SubCauses-sponsor div h3 {
    color: black;
}

.SubCauses-sponsor a {
    color: black;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.75rem;
    padding: 0 0.25rem;
}

.SubCauses-choice {
    border-bottom: 0.5rem solid #003B71;
}

.SubCauses-choice section {
    background: #003B71;
}

.SubCauses-choice div h3 {
    color: #003B71;
}

.SubCauses-choice a {
    color: #003B71;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.75rem;
    padding: 0 0.25rem;
}

.SubCauses-voice {
    border-bottom: 0.5rem solid #922C48;
}

.SubCauses-voice section {
    background: #922C48;
}

.SubCauses-voice div h3 {
    color: #922C48;
}

.SubCauses-voice a {
    color: #922C48;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.75rem;
    padding: 0 0.25rem;
}

.SubCauses-vote {
    border-bottom: 0.5rem solid #225D38;
}

.SubCauses-vote section {
    background: #225D38;
}

.SubCauses-vote div h3 {
    color: #225D38;
}

.SubCauses-vote a {
    color: #225D38;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.75rem;
    padding: 0 0.25rem;
}

#SubCauses-cause-overview {
    display: none;
    background-color: rgba(255, 255, 255, 0) !important;
    background: rgba(255, 255, 255, 0) !important;
}

#SubCauses-cause-overview div {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    padding: 0.75rem 0 2rem 0;
    margin-bottom: 4rem;
}

#SubCauses-cause-overview div img {
    width: 100%;
    margin-top: 1rem;
}

#SubCauses-cause-overview-main-image {
    border-top: 0.4rem solid #922C48 !important;
}

#SubCauses-cause-overview div h1 {
    font-family: serif;
    font-size: 1.5rem;
    margin-top: 1.5rem;
}

#SubCauses-cause-overview div p {
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 0.8rem;
    font-weight: normal;
    line-height: 1.1rem;
    color: rgba(0, 0, 0, 0.6);
}

#SubCauses-cause-overview div h5 {
    font-size: 0.8rem;
    text-align: center;
    letter-spacing: 0.05rem;
    margin-top: 2rem;
    opacity: 0.8;
}

#SubCauses-cause-overview div section {
    display: flex;
    max-width: 50%;
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 0;
}

#SubCauses-cause-overview div a {
    height: 1.25rem;
    width: 1.25rem;
}

#SubCauses-cause-overview div a img {
    height: 1.25rem;
    width: 1.25rem;
}

#SubCauses-cause-overview-logo-container {
    display: flex;
    max-width: 80%;
    width: 80%;
    margin: 1rem auto 0.5rem auto !important;
}

#SubCauses-cause-overview h4 {
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 0.65rem;
}

#SubCauses-cause-overview-share-section {
    padding: 0.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin: 2rem 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    transition: all 0.25s;
}

#SubCauses-cause-overview-share-section:hover {
    background: rgb(217, 222, 228);
}

#SubCauses-cause-overview-share-section:active {
    background: rgb(206, 211, 216);
}

#SubCauses-cause-overview-share-section span {
    font-size: 0.8rem;
    font-weight: bold;
    color: #922C48;
}

#SubCauses-cause-overview-share-section img {
    height: 1.2rem;
    width: auto !important;
    margin: 0 !important;
    margin-left: 0.5rem !important;
}

#SubCauses-cause-overview-share-section-visible {
    padding: 0.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#SubCauses-cause-overview-share-section-visible span {
    font-size: 0.8rem;
    font-weight: bold;
    color: #225D38;
}

#SubCauses-cause-overview-share-section-visible figure {
    display: flex;
    justify-content: space-between;
    width: 55%;
}

#SubCauses-cause-overview-share-section-visible figure a {
    height: 1.25rem;
    width: 1.25rem;
}

#SubCauses-cause-overview-share-section-visible figure img {
    height: 1.25rem;
    width: 1.25rem;
    margin: 0 !important;
    max-width: 100% !important;
}

.SubCauses-cause-overview-share-image {
    cursor: pointer;
    position: relative;
}

#SubCauses-cause-overview-copy-popup {
    position: absolute; 
    background: white;
    padding: 0.5rem;
    z-index: 1000; 
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2); 
    right: 3.7rem;
    display: flex;
    justify-content: center !important;
}

@media only screen and (max-width: 700px) {
    #SubCauses {
        top: 6rem;
        margin: 0;
        padding: 2rem;
    }
    #SubCauses-cause-overview {
        display: block;
    }
}

@media only screen and (max-width: 470px) {
    #SubCauses {
        padding: 1rem;
    }
}